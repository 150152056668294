<template>
  <div class="event-item">
    <div class="item-name">{{ nftId }}</div>
    <div class="type">
      {{ event.event }}
    </div>
    <div class="price">
      {{ price ? price : "-" }}
    </div>
    <div class="quantity">
      {{ quantity ? quantity : "-" }}
    </div>
    <div class="seller">
      <template v-if="seller">
        {{ seller.substring(0, 4) }}...{{
          seller.substring(seller.length - 4, seller.length)
        }}
      </template>
      <template v-else>-</template>
    </div>
    <div class="contract">
      <template v-if="nftContract">
        {{ nftContract.substring(0, 4) }}...{{
          nftContract.substring(nftContract.length - 4, nftContract.length)
        }}
      </template>
      <template v-else>-</template>
    </div>
  </div>
</template>

<script>
import { ethers } from "ethers";
import { onMounted, ref } from "vue";

export default {
  name: "EventItem",
  props: {
    event: {
      // small || extra-small || default large
      type: Object,
    },
  },
  setup(props) {
    const nftId = ref(0);
    const price = ref(null);
    const quantity = ref(null);
    const seller = ref(null);
    const nftContract = ref(null);
    const isERC1155 = ref(false);

    onMounted(async () => {
      nftId.value = ethers.utils.formatUnits(props.event.args?.nftId, 0);
      price.value = ethers.utils.formatEther(props.event.args?.price);
      quantity.value = ethers.utils.formatUnits(props.event.args?.quantity, 0);
      seller.value = props.event.args?.address;
      nftContract.value = props.event.args?.nftContract;
      isERC1155.value = props.event.args?.isERC1155;
    });

    return {
      nftId,
      price,
      quantity,
      seller,
      nftContract,
      isERC1155,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.event-item {
  margin: 1rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}
</style>
