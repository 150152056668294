<template>
  <div class="wrapper">
    <div class="events">
      <div class="event-item">
        <div class="item-name">NFT Id</div>
        <div class="type">Event</div>
        <div class="price">Price</div>
        <div class="quantity">Quantity</div>
        <div class="seller">Seller</div>
        <div class="contract">Contract</div>
      </div>
      <EventItem
        :key="event.blockNumber"
        v-for="event in events"
        :event="event"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// import SkeletalLoading from "../components/LoadingComponents/SkeletalLoading.vue";
import Constants from "../../consts/constants";
// import { useRoute } from "vue-router";
import { ethers } from "ethers";
// import ItemTile from "../components/AdvancedMarketplace/ItemTile.vue";
import EventItem from "../../components/AdvancedMarketplace/EventItem.vue";
import { getLoadMarketplaceContract } from "../../utils/getContract";

export default {
  displayName: "Activity",
  components: {
    EventItem,
    // SkeletalLoading,
    // ItemTile,
  },
  setup() {
    const events = ref([]);
    const rpcProvider = new ethers.providers.JsonRpcProvider(Constants.rpcUrl);
    const MarketplaceContract = getLoadMarketplaceContract(rpcProvider);

    onMounted(async () => {
      getMarketplaceEvents();
    });

    const getMarketplaceEvents = async () => {
      try {
        const currentBlock = await rpcProvider.getBlockNumber();
        console.log(currentBlock);
        events.value = await MarketplaceContract.queryFilter(
          "*",
          currentBlock - 2000,
          currentBlock
        );
        console.log(events.value);
      } catch (error) {
        console.log(error);
      }
    };

    return {
      events,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/globals.scss";

.header {
  grid-template-columns: 150px 1fr 2fr 200px;
  display: grid;
  padding: 1rem;
  align-items: center;
  grid-gap: 1rem;
  background: #171a1c;
  margin-bottom: 2rem;
  .logo {
    width: 150px;
  }
  .menu {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    list-style: none;
    li a {
      text-transform: uppercase;
      text-decoration: none;
    }
  }
  .search {
    #search {
      width: 90%;
      padding: 12px 10px;
      font-size: 1.1rem;
      background: #101214;
      box-shadow: none;
      border: none;
      border-radius: 10px;
    }
  }
}
.wrapper {
  display: block;
  max-width: 1616px;
  margin: 0 auto;
  padding: 0 1rem;
  .event-item {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 10px;
    div {
      background-color: #000000b0;
      padding: 5px 10px;
    }
  }
}
</style>
